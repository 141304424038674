<template>
  <v-dialog
    v-model='isOpen'
    max-width='1000px'
    transition='dialog-bottom-transition'
  >
    <v-card class='pa-5'>
      <v-card-title class='headline pa-0 mb-3'>Possible Employee Matches</v-card-title>
      <v-data-table
        :headers='originalEmployeeHeaders'
        :items='[employee]'
        class='mb-6'
        hide-default-footer
      >
        <template #top>
          <v-toolbar-title>Original Employee Data</v-toolbar-title>
        </template>
      </v-data-table>
      <v-data-table
        v-model='selected'
        :headers='mismatchHeaders'
        :items='employee.exceptions_to'
        :loading='loadingMismatch'
        checkbox-color='primary'
        class='mb-3'
        show-select
        single-select
      >
        <template #top>
          <v-toolbar-title>Possible Matches</v-toolbar-title>
        </template>
      </v-data-table>
      <v-card-actions class='justify-end pa-0'>
        <v-btn
          :loading='loadingNoMatch'
          color='primary'
          @click='sendNoMatchListed'
        >
          No Match Listed
        </v-btn>
        <v-btn
          color='primary'
          @click='closeDialogWithoutRefresh'
        >
          Cancel
        </v-btn>
        <v-btn
          :disabled='checkSelected'
          :loading='loadingUpdate'
          color='primary'
          @click='onUpdate'
        >
          Update
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'ResolveMismatchDialog',
  props: {
    isOpen: {
      type: Boolean,
      default: false,
      required: true
    },
    employee: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      originalEmployeeHeaders: [
        { text: 'First Name', value: 'first_name' },
        { text: 'Last Name', value: 'last_name' },
        { text: 'Address', value: 'address' },
        { text: 'City', value: 'city' },
        { text: 'Zip', value: 'zip' },
        { text: 'County', value: 'county.text' },
        { text: 'Birth Year', value: 'birth_day' }
      ],
      mismatchHeaders: [
        { text: 'First Name', value: 'first_name' },
        { text: 'Last Name', value: 'last_name' },
        { text: 'Address', value: 'address' },
        { text: 'City', value: 'city' },
        { text: 'Zip', value: 'zip' },
        { text: 'County', value: 'county.text' },
        { text: 'Birth Year', value: 'birth_day' }
      ],
      loadingMismatch: false,
      loadingNoMatch: false,
      loadingUpdate: false,
      selected: []
    }
  },
  computed: {
    checkSelected() {
      return !this.selected?.length
    }
  },
  methods: {
    ...mapActions('adminStore', ['updateEmployee', 'noMatchListed', 'getAllEmployees', 'getExceptionItems']),
    async onUpdate() {
      this.loadingUpdate = true
      const data = {
        id: this.employee.id,
        selectedId: this.selected[0].id
      }
      await this.updateEmployee(data)
      await this.getExceptionItems()
      this.closeDialog()
      this.loadingUpdate = false
    },
    async sendNoMatchListed() {
      this.loadingNoMatch = true
      await this.noMatchListed({ id: this.employee.id })
      await this.getExceptionItems()
      this.closeDialog()
      this.loadingNoMatch = false
    },
    closeDialog() {
      this.selected = []
      this.$emit('close-dialog')
    },
    closeDialogWithoutRefresh() {
      this.selected = []
      this.$emit('close-without-refresh')
    }
  }
}
</script>

<style scoped>

</style>