<template>
  <v-dialog
    v-model='importEmployee'
    max-width='800px'
    transition='dialog-bottom-transition'
  >
    <template #activator={on,attrs}>
      <v-btn
        class='text-capitalize font-weight-bold ml-2'
        color='primary'
        height='40'
        outlined
        v-bind='attrs'
        v-on='on'
      >
        <v-icon left> mdi-import</v-icon>
        {{ title }}
      </v-btn>
    </template>
    <v-card class='pa-5'>
      <v-card-title class='pa-0 mb-3 headline font-weight-bold'>{{ title }}</v-card-title>
      <v-card-text class='pa-0 mb-3'>
        <v-card-subtitle
          v-if='showInstruction'
          class='pa-0 mb-3'
        >
          To update current employee data, download the current employees list and modify. All information changed or
          added to the list will be updated in the portal upon upload.
        </v-card-subtitle>
        <p class='font-weight-light my-0 red--text'>Please do not upload a file with filters.</p>
        <p class='font-weight-light mb-2'>Xlsx file*</p>
        <div
          class='addFile mb-3'
          @dragleave='dragleave'
          @dragover.prevent='dragover'
          @drop.prevent='drop'
        >
          <input
            id='addFile'
            ref='file'
            accept='.xlsx'
            class='addFile__input'
            type='file'
            @change='onChange'
          />
          <label class='addFile__label' for='addFile'>
            <span>Add a file or drop it <span class='text-decoration-underline'>here</span></span>
          </label>
          <ul v-if='fileImportEmployees' class='addFile__list'>
            <li v-for='file in fileImportEmployees' :key='file.name' class='addFile__list-item'>
              {{ file.name }}
              <button
                class='addFile__list-btn gg-close'
                title='Remove file'
                type='button'
                @click='remove'
              >
              </button>
            </li>
          </ul>
        </div>
        <div class='d-flex justify-end align-center'>
          <v-btn
            class='text-capitalize'
            outlined
            text
            @click='downloadTemplate'
          >
            {{ downloadButtonName }}
            <v-icon class='ml-4' left> mdi-download</v-icon>
          </v-btn>
        </div>
      </v-card-text>
      <v-card-actions class='pa-0 d-flex direction-row justify-center'>
        <v-btn
          class='px-14 mr-4 font-weight-bold'
          color='primary'
          large
          outlined
          @click='closeImportEmployees'
        >
          Cancel
        </v-btn>
        <v-btn
          :disabled='!fileImportEmployees'
          :loading='loadingSubmitFile'
          class='px-14 font-weight-bold white--text'
          color='primary'
          large
          @click='saveImportEmployees'
        >
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'add-file-dialog',
  props: {
    title: {
      type: String,
      required: true
    },
    downloadButtonName: {
      type: String,
      required: true
    },
    downloadLink: {
      type: String,
      required: true
    },
    showInstruction: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loadingSubmitFile: false,
      fileImportEmployees: null,
      importEmployee: false
    }
  },
  computed: {
    ...mapGetters(['currentUserID']),
    downloadTemplateLink() {
      return this.downloadLink === 'api/employee-exports'
        ? `${process.env.VUE_APP_DOWNLOAD_URL}${this.downloadLink}/${this.currentUserID}`
        : `${process.env.VUE_APP_DOWNLOAD_URL}${this.downloadLink}`
    }
  },
  methods: {
    ...mapActions({
      importEmployees: 'memberStore/importEmployees',
      updateEmployees: 'memberStore/updateEmployees'
    }),
    onChange() {
      this.fileImportEmployees = this.$refs.file.files
    },
    drop(e) {
      this.$refs.file.files = e.dataTransfer.files
      this.onChange()
      e.currentTarget.classList.remove('active')
    },
    dragover(event) {
      event.currentTarget.classList.add('active')
    },
    dragleave(event) {
      event.currentTarget.classList.remove('active')
    },
    downloadTemplate() {
      location.href = this.downloadTemplateLink
    },
    remove() {
      this.fileImportEmployees = null
      this.$refs.file.value = null
    },
    closeImportEmployees() {
      this.remove()
      this.importEmployee = false
    },
    async saveImportEmployees() {
      this.loadingSubmitFile = true
      let formData = new FormData()
      formData.append('file', this.fileImportEmployees['0'])
      if (this.title === 'Import Employees') await this.importEmployees(formData)
      if (this.title === 'Update Employees') await this.updateEmployees(formData)
      this.closeImportEmployees()
      this.loadingSubmitFile = false
    }
  }
}
</script>
<style lang='scss'>
.gg-close {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid transparent;
  border-radius: 40px
}
.gg-close::after,
.gg-close::before {
  content: '';
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 16px;
  height: 2px;
  background: currentColor;
  transform: rotate(45deg);
  border-radius: 5px;
  top: 8px;
  left: 1px
}
.gg-close::after {
  transform: rotate(-45deg)
}
.addFile {
  background: #ece9e9;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 200px;
  border-radius: 5px;
  transition: .3s;
  padding: 0 15px;
  &__input {
    opacity: 0;
    position: absolute;
    z-index: -1;
    overflow: hidden;
    width: 0.4px;
    height: 0.4px;
  }
  &__label {
    cursor: pointer;
  }
  &__list {
    padding: 0 !important;
    width: 100%;
    background: #fcfcfc;
    border-radius: 5px;
    margin-top: 15px;
    &-item {
      list-style-type: none;
      padding: 10px;
      display: flex;
      justify-content: space-between;
      font-size: 18px;
    }
  }
}
</style>