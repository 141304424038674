<template>
  <v-dialog
    v-model='isOpen'
    max-width='1000px'
    transition='dialog-bottom-transition'
    persistent
  >
    <v-card class='pa-5'>
      <v-card-title class='headline pa-0 mb-3'>Possible Employee Matches</v-card-title>
      <v-data-table
        v-model='selectedEmployee'
        :headers='originalEmployeeHeaders'
        :items='employees'
        class='mb-6'
        hide-default-footer
        single-select
        selectable-key='id'
        show-select
        @item-selected='selectEmployee'
      >
        <template #top>
          <v-toolbar-title>Original Employee Data</v-toolbar-title>
        </template>
      </v-data-table>
      <v-data-table
        v-model='selectedMismatch'
        :headers='mismatchHeaders'
        :items='exceptionsTo'
        :loading='loadingMismatch'
        checkbox-color='primary'
        class='mb-3'
        show-select
        single-select
        :no-data-text='"You need to select employee"'
      >
        <template #top>
          <v-toolbar-title>Possible Matches</v-toolbar-title>
        </template>
      </v-data-table>
      <v-card-actions class='justify-end pa-0'>
        <v-btn
          color='primary'
          @click='closeDialog'
        >
          Cancel
        </v-btn>
        <v-btn
          :disabled='checkSelected'
          :loading='loadingUpdate'
          color='primary'
          @click='onUpdate'
        >
          Update
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'RescreenDialog',
  props: {
    isOpen: {
      type: Boolean,
      default: true,
      required: true
    },
    data: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      originalEmployeeHeaders: [
        { text: 'First Name', value: 'firstName' },
        { text: 'Last Name', value: 'lastName' },
        { text: 'Address', value: 'address' },
        { text: 'City', value: 'city' },
        { text: 'Zip', value: 'zip' },
        { text: 'County', value: 'county.text' },
        { text: 'Birth Year', value: 'birthDay' }

      ],
      mismatchHeaders: [
        { text: 'First Name', value: 'firstName' },
        { text: 'Last Name', value: 'lastName' },
        { text: 'Address', value: 'address' },
        { text: 'City', value: 'city' },
        { text: 'Zip', value: 'zip' },
        { text: 'County', value: 'county.text' },
        { text: 'Birth Year', value: 'birthDay' }
      ],
      loadingMismatch: false,
      loadingNoMatch: false,
      loadingUpdate: false,
      selectedMismatch: [],
      selectedEmployee: [],
      exceptionsTo: [],
      employees: []
    }
  },
  beforeMount() {
    this.employees = this.data
  },
  computed: {
    checkSelected() {
      return !(this.selectedEmployee?.length && this.selectedMismatch?.length)
    }
  },
  methods: {
    ...mapActions({
      resolveRescreenException: 'employeeStore/resolveRescreenException',
      getAllEmployees: 'adminStore/getAllEmployees'
    }),
    async selectEmployee(employee) {
      this.exceptionsTo = employee.item.exceptionsTo
    },
    async onUpdate() {
      this.loadingUpdate = true
      const data = {
        id: this.selectedEmployee[0].id,
        selectedId: this.selectedMismatch[0].id
      }
      await this.resolveRescreenException(data)
      this.clear()
      this.employees.length === 0 ? this.closeDialog() : null
      this.loadingUpdate = false
    },
    clear() {
      this.employees = this.employees.filter(el => el.id !== this.selectedEmployee[0].id)
      this.exceptionsTo = []
      this.selectedMismatch = []
      this.selectedEmployee = []
    },
    closeDialog() {
      this.selectedMismatch = []
      this.selectedEmployee = []
      this.$emit('close-dialog')
    }
  }
}
</script>

<style scoped>

</style>
