<template>
  <v-dialog
    v-model='isOpen'
    max-width='1000'
    width='100%'
    transition='dialog-bottom-transition'
    @click:outside='closeDialog'
  >
    <v-card class='pt-8'>
      <v-form ref='createForm'>
        <v-card-title class='px-7 py-0 mb-6'>
          <span class='headline font-weight-bold'>{{ isEdit ? 'Edit Employee' : 'New Employee' }}</span>
          <v-btn
            v-if='!isEdit'
            class='px-14 ml-4 font-weight-bold white--text'
            color='primary'
            large
            @click='$emit("go-to-search")'
          >
            Search Employee
          </v-btn>
        </v-card-title>
        <v-row class='ma-0 px-4'>
          <v-col cols='6' class='py-0'>
            <p class='font-weight-bold'>First Name</p>
            <v-text-field
              v-model='fields.firstName'
              :rules='[fieldsRules.required]'
              dense
              outlined
              placeholder='i.e John'
              @change='fieldWasChanged'
            ></v-text-field>
          </v-col>
          <v-col cols='6' class='py-0'>
            <p class='font-weight-bold'>Middle Name</p>
            <v-text-field
              v-model='fields.secondName'
              dense
              outlined
              placeholder='i.e Phill'
              @change='fieldWasChanged'
            ></v-text-field>
          </v-col>
          <v-col cols='6' class='py-0'>
            <p class='font-weight-bold'>Last Name</p>
            <v-text-field
              v-model='fields.lastName'
              :rules='[fieldsRules.required]'
              dense
              outlined
              placeholder='i.e Williams'
              @change='fieldWasChanged'
            ></v-text-field>
          </v-col>
          <v-col cols='6' class='py-0'>
            <p class='font-weight-bold'>Birth Year</p>
            <year-picker v-model='fields.birthDate'/>
          </v-col>
          <v-col cols='6' class='py-0'>
            <p class='font-weight-bold'>Mobile Phone</p>
            <v-text-field
              v-model='fields.mobilePhone'
              :rules='[fieldsRules.phone]'
              dense
              outlined
              placeholder='i.e 2123456789'
            ></v-text-field>
          </v-col>
          <v-col cols='6' class='py-0'>
          </v-col>
          <v-col cols='12' class='pb-5 pt-0'>
            <hr>
          </v-col>
          <v-col cols='6' class='py-0'>
            <p class='font-weight-bold'>Mailing Address</p>
            <v-text-field
              v-model='fields.mailAddress'
              dense
              outlined
              placeholder='i.e Lincoln Square 123'
              @change='fieldWasChanged'
            ></v-text-field>
          </v-col>
          <v-col cols='6' class='py-0'>
            <p class='font-weight-bold'>Mailing City</p>
            <v-text-field
              v-model='fields.mailCity'
              dense
              outlined
              placeholder='i.e Chicago'
              @change='fieldWasChanged'
            ></v-text-field>
          </v-col>
          <v-col cols='6' class='py-0'>
            <p class='font-weight-bold'>Mailing State</p>
            <v-text-field
              v-model='fields.mailState'
              dense
              outlined
              placeholder='i.e Illinois'
              @change='fieldWasChanged'
            ></v-text-field>
          </v-col>
          <v-col cols='6' class='py-0'>
            <p class='font-weight-bold'>Mailing Zip Code</p>
            <v-text-field
              v-model='fields.mailZip'
              dense
              outlined
              placeholder='i.e 9851123'
              @change='fieldWasChanged'
            ></v-text-field>
          </v-col>
          <v-col cols='6' class='py-0'>
            <p class='font-weight-bold'>County</p>
            <v-select
                v-model='fields.county'
                :items='[{ value: null, text: "" }, ...this.counties]'
                class='mt-3'
                dense
                label='County'
                outlined
                placeholder='County'
            ></v-select>
          </v-col>
          <v-col cols='6' class='py-0'>
          </v-col>
          <v-col cols='12' class='pb-5 pt-0'>
            <hr>
          </v-col>
          <v-col cols='6' class='py-0'>
            <p class='font-weight-bold'>Voter Registration Address</p>
            <v-text-field
              v-model='fields.voterAddress'
              dense
              outlined
              placeholder='i.e Lincoln Square 123'
            ></v-text-field>
          </v-col>
          <v-col cols='6' class='py-0'>
            <p class='font-weight-bold'>Voter Registration City</p>
            <v-text-field
              v-model='fields.voterCity'
              dense
              outlined
              placeholder='i.e Chicago'
            ></v-text-field>
          </v-col>
          <v-col cols='6' class='py-0'>
            <p class='font-weight-bold'>Voter Registration State</p>
            <v-text-field
              v-model='fields.voterState'
              dense
              outlined
              placeholder='i.e Illinois'
              @change='fieldWasChanged'
            ></v-text-field>
          </v-col>
          <v-col cols='6' class='py-0'>
            <p class='font-weight-bold'>Voter Registration Zip Code</p>
            <v-text-field
              v-model='fields.voterZip'
              dense
              outlined
              placeholder='i.e 9851123'
            ></v-text-field>
          </v-col>
          <v-col cols='6' class='py-0'>
            <p class='font-weight-bold'>Voter Registration County</p>
            <v-select
              v-model='fields.voterCounty'
              :items='[{ value: null, text: "" }, ...this.counties]'
              class='mt-3'
              dense
              label='Voter Registration County'
              outlined
              placeholder='Voter Registration County'
            ></v-select>
          </v-col>
          <v-col cols='6' class='py-0'>
          </v-col>
          <v-col cols='12' class='pb-5 pt-0'>
            <hr>
          </v-col>
          <v-col cols='6' class='py-0'>
            <p class='font-weight-bold'>Primary Email</p>
            <v-text-field
                v-model='fields.email'
                :rules='[fieldsRules.required, fieldsRules.email]'
                dense
                outlined
                placeholder='i.e john@mydomain.com'
            ></v-text-field>
          </v-col>
          <v-col cols='6' class='py-0'>
            <p class='font-weight-bold'>VUID</p>
            <v-text-field
              v-model='fields.vuid'
              dense
              outlined
              placeholder='i.e 123'
            ></v-text-field>
          </v-col>
          <v-col cols='6' class='py-0'>
            <p class='font-weight-bold'>Department</p>
            <v-text-field
              v-model='fields.department'
              dense
              outlined
              placeholder='depa...'
            ></v-text-field>
          </v-col>
          <v-col cols='6' class='py-0'>
            <p class='font-weight-bold'>Location</p>
            <v-text-field
              v-model='fields.location'
              dense
              outlined
              placeholder='location'
            ></v-text-field>
          </v-col>

          <v-col cols='6' class='py-0'>
            <p class='font-weight-bold'>Member</p>
            <v-autocomplete
              v-model='fields.memberId'
              :rules='[fieldsRules.required]'
              :items='members'
              item-value='id'
              item-text='name'
              dense
              outlined
              placeholder='Member'
              @change='getEmployeesToReport'
            ></v-autocomplete>
          </v-col>
          <v-col cols='6' class='py-0'>
            <p class='font-weight-bold'>Reports To</p>
            <v-autocomplete
              v-model='fields.reportsTo'
              :items='employeesToReport'
              :rules='[fieldsRules.required]'
              :disabled='!fields.memberId'
              dense
              item-text='fullName'
              item-value='id'
              label='Who do you report to?'
              outlined
            ></v-autocomplete>
          </v-col>
          <v-col cols='6' class='py-0'>
            <p class='font-weight-bold'>Hierarchy Level</p>
            <v-select
              v-model='fields.hierarchy'
              :items='employeeRoles'
              :rules='[fieldsRules.required]'
              dense
              item-text='description'
              item-value='id'
              label='Select your role'
              outlined
            ></v-select>
          </v-col>
        </v-row>
        <v-row class='ma-0 px-4'>
          <v-col cols='6' class='py-0'>
            <p class='font-weight-bold'>Employee Eligibility</p>
            <div class='d-flex align-center'>
              <v-checkbox
                v-model='fields.inelegible'
                color='warning'
                class='checkbox'
              >
              </v-checkbox>
              <span style='font-size: 16px;'>This employee is ineligible for voting campaigns</span>
            </div>
            <p
              v-if='fields.inelegible'
              class='font-weight-bold warning--text px-4'
            >
              Warning: An Employee with the ineligible option selected will be denied the possibility
              of scheduling time for voting or viewing the MCBA Recommendations.
            </p>
          </v-col>
          <v-col
            v-if='fields.inelegible'
            cols='6'
            class='py-0'
          >
            <p class='font-weight-bold'>Eligibility Detail</p>
            <v-select
              v-model='fields.eligibilityDetailId'
              :items='eligibilityDetails'
              :rules='[fieldsRules.required]'
              label='Eligibility Detail'
              dense
              item-text='title'
              item-value='id'
              outlined
            ></v-select>
          </v-col>
        </v-row>
        <v-card-actions class='d-flex direction-row justify-center'>
          <v-btn
            class='px-14 ma-4 font-weight-bold'
            color='primary'
            large
            outlined
            @click='closeDialog'
          >
            Cancel
          </v-btn>
          <v-btn
            v-if='!isEdit'
            :loading='isCreating'
            class='px-14 ma-4 font-weight-bold white--text'
            color='primary'
            large
            @click='onCreate'
          >
            Submit
          </v-btn>
          <v-btn
            v-if='isEdit'
            :loading='isUpdatingWithoutRescreen'
            class='px-14 ma-4 font-weight-bold white--text'
            color='primary'
            large
            @click='onUpdateWithoutRescreen'
          >
            Save
          </v-btn>
          <v-btn
            v-if='isEdit'
            :loading='isUpdatingWithRescreen'
            class='px-14 ma-4 font-weight-bold white--text'
            color='primary'
            large
            @click='onUpdateWithRescreen'
          >
            Screen Employee
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import YearPicker from '@/components/year-picker.vue'

export default {
  name: 'CreateEmployeeDialog',
  components: { YearPicker },
  props: {
    isEdit: {
      type: Boolean,
      default: false
    },
    selectedEmployee: {
      type: Object
    },
    isOpen: {
      type: Boolean,
      default: false
    },
    isCreating: {
      type: Boolean,
      default: false
    },
    isUpdatingWithoutRescreen: {
      type: Boolean,
      default: false
    },
    isUpdatingWithRescreen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      fields: {
        firstName: '',
        secondName: '',
        lastName: '',
        birthDate: '',
        homePhone: '',
        workPhone: '',
        mobilePhone: '',
        county: '',
        mailAddress: '',
        mailCity: '',
        mailState: '',
        mailZip: '',
        voterAddress: '',
        voterCity: '',
        voterState: '',
        voterZip: '',
        voterCounty: '',
        vuid: '',
        department: '',
        location: '',
        email: '',
        secondaryEmail: '',
        memberId: null,
        reportsTo: '',
        hierarchy: '',
        inelegible: false,
        eligibilityDetailId: null
      },
      employeeRoles: [],
      eligibilityDetails: [],
      fieldsRules: {
        required: v => !!v || 'Field is required',
        phone: v => v ? ((/^[0-9]+$/.test(v) && v?.length === 10) || 'Value must be a number and be equal to 10 characters') : true,
        email: v => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return v ? (pattern.test(v) || 'Invalid e-mail') : true
        }
      },
      isAnyFieldWasChanged: false
    }
  },
  computed: {
    ...mapState({
      members: s => s.adminStore.members,
      counties: s => s.communicationStore.counties,
      employeesToReport: s => s.employeeStore.employeesToReport
    })
  },
  watch: {
    async isOpen(value) {
      if (value) {
        if (!Object.keys(this.selectedEmployee).length)
          this.resetForm()
        await Promise.allSettled([
          this.getAllMembers(),
          this.getCounties(),
          this.getEmployeeRoles(),
          this.getEligibilityDetails()
        ])
      }
    },
    selectedEmployee: {
      handler(value) {
        for (const key in value) {
          if (Object.prototype.hasOwnProperty.call(this.fields, key)) {
            this.fields[key] = value[key]
          }
        }
      },
      deep: true
    },
    'fields.memberId': {
      async handler(value) {
        if (value && this.isEdit) {
          await this.getItemsToReport(this.fields.memberId)
        }
      },
      deep: true
    }
  },
  methods: {
    ...mapActions({
      getAllMembers: 'adminStore/getMembers',
      getCounties: 'communicationStore/getCounties',
      getItemsToReport: 'employeeStore/getEmployeesToReport',
      getRoles: 'employeeStore/getEmployeesRoles',
      getEligibilities: 'employeeStore/getEligibilityDetails'
    }),
    async getEmployeeRoles() {
      this.employeeRoles = await this.getRoles()
    },
    async getEligibilityDetails() {
      this.eligibilityDetails = await this.getEligibilities()
    },
    async getEmployeesToReport() {
      this.fieldWasChanged()
      await this.getItemsToReport(this.fields.memberId)
      this.fields.reportsTo = ''
    },
    onCreate() {
      if (this.$refs.createForm.validate())
        this.$emit('create', this.fields)
    },
    onUpdateWithoutRescreen() {
      if (this.$refs.createForm.validate())
        this.$emit('update-without-rescreen', {
          id: this.selectedEmployee.id,
          rescreen: this.isAnyFieldWasChanged,
          ...this.fields
        })
    },
    onUpdateWithRescreen() {
      if (this.$refs.createForm.validate())
        this.$emit('update-with-rescreen', {
          id: this.selectedEmployee.id,
          rescreen: this.isAnyFieldWasChanged,
          ...this.fields
        })
    },
    closeDialog() {
      this.$emit('close')
      this.resetForm()
    },
    resetForm() {
      this.$nextTick(() => {
        this.fields = {
          firstName: '',
          secondName: '',
          lastName: '',
          birthDate: '',
          homePhone: '',
          workPhone: '',
          mobilePhone: '',
          county: '',
          mailAddress: '',
          mailCity: '',
          mailState: '',
          mailZip: '',
          voterAddress: '',
          voterCity: '',
          voterState: '',
          voterZip: '',
          voterCounty: '',
          vuid: '',
          department: '',
          location: '',
          email: '',
          secondaryEmail: '',
          memberId: null,
          reportsTo: '',
          hierarchy: '',
          inelegible: false,
          eligibilityDetailId: null
        }
        this.$refs.createForm.resetValidation()
      })
    },
    fieldWasChanged() {
      this.isAnyFieldWasChanged = true
    }
  }
}
</script>

<style scoped>

</style>
