<template>
  <v-dialog
    v-model='isOpen'
    max-width='550px'
    width='100%'
    @click:outside='$emit("close")'
  >
    <v-card class='d-flex flex-column align-start pa-2'>
      <v-card-title class='text-center mb-5'>
        <v-icon class='mr-4 white--text' color='error' large left>mdi-alert</v-icon>
        Confirm Delete Employee
      </v-card-title>
      <v-card-subtitle>
        <b v-if='items.length === 1'>
          Are you sure you want to delete this employee permanently?
          <span class='error--text'>There is no way to undo this action.</span>
        </b>
        <b v-else>
          Are you sure you want to delete these employees permanently?
          <span class='error--text'>There is no way to undo this action.</span>
        </b>
      </v-card-subtitle>
      <div>
        <p
          v-for='item in items'
          :key='item.id'
          class='ml-8 font-weight-bold secondary--text'
        >
          {{ item.fullName }}
        </p>
      </div>
      <v-card-actions class='align-self-center'>
        <v-spacer></v-spacer>
        <v-btn
          class='px-14 mx-4 font-weight-bold white--text'
          color='primary'
          large
          outlined
          @click='$emit("close")'
        >
          Cancel
        </v-btn
        >
        <v-btn
          :loading='isLoading'
          class='px-14 mx-4 font-weight-bold white--text'
          color='error'
          large
          @click='$emit("submit")'
        >
          Yes, Delete Now
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DeleteEmployeeConfirmDialog',
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  }
}
</script>
