<template>
  <v-data-table
    :headers='tableHeaders'
    :items='uploadedFiles'
    :loading='isTableLoading'
    :options.sync='tableOptions'
    :server-items-length='tableTotalItems'
    :items-per-page='10'
    loading-text='Loading uploaded files... Please wait'
    no-data-text='There are no uploaded files at the moment.'
  >
    <template #top>
      <v-toolbar flat>
        <v-toolbar-title class='font-weight-bold'>Upload History</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
    </template>
  </v-data-table>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'UploadHistoryTable',
  data() {
    return {
      tableOptions: {},
      tableTotalItems: 0,
      isTableLoading: false,
      tableHeaders: [
        { text: 'Name', value: 'user' },
        { text: 'Title', value: 'title' },
        { text: 'Date', value: 'createDate' }
      ]
    }
  },
  computed: {
    ...mapState('adminStore', ['uploadedFiles'])
  },
  watch: {
    tableOptions: {
      async handler() {
        await this.getUploadedHistory()
      },
      deep: true
    }
  },
  methods: {
    ...mapActions({
      getUploadedFiles: 'adminStore/getUploadedFiles'
    }),
    async getUploadedHistory() {
      this.isTableLoading = true
      const { itemsPerPage, page, sortBy, sortDesc } = this.tableOptions
      const params = {
        page,
        per_page: itemsPerPage > 0 ? itemsPerPage : 'all',
        sort_by: sortBy ? sortBy[0] : null,
        order: sortBy ? (sortDesc[0] ? 'desc' : 'asc') : null
      }
      const { meta: { total } } = await this.getUploadedFiles(params)
      this.tableTotalItems = total
      this.isTableLoading = false
    }
  }
}
</script>
