<template>
  <div v-frag>
    <v-data-table
      v-model='selectedEmployees'
      :headers='tableHeaders'
      :items='visibleItems'
      :loading='isTableLoading'
      :options.sync='tableOptions'
      :server-items-length='tableTotalItems'
      :items-per-page='10'
      checkboxColor='primary'
      item-key='id'
      loading-text='Loading employees... Please wait'
      no-data-text='There are no suspended employees at the moment.'
      show-select
      must-sort
    >
      <template #top>
        <v-toolbar flat>
          <v-toolbar-title class='font-weight-bold'>Suspended Employees</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field
            v-model='tableSearch'
            class='mr-4'
            dense
            hide-details
            label='Search'
            outlined
            prepend-inner-icon='mdi-magnify'
            single-line
            @input='searchEmployees'
          ></v-text-field>
        </v-toolbar>
        <v-row
          v-if='selectedEmployees.length'
          class='d-flex justify-start align-center ml-4 my-4'
        >
          <v-btn
            class='error--text mr-4'
            medium
            outlined
            @click='openDeleteDialog(selectedEmployees)'
          >
            <v-icon left>mdi-close-thick</v-icon>
            Delete
          </v-btn>
          <v-btn
            class='success--text'
            medium
            outlined
            @click='openReincorporateDialog(selectedEmployees)'
          >
            <v-icon left>mdi-undo</v-icon>
            Reincorporate
          </v-btn>
        </v-row>
        <v-alert
          v-model='isDeleteAlertShowing'
          class='rounded-xl'
          color='grey'
          dark
          dense
          dismissible
          icon='mdi-information-outline'
          prominent
          transition='scale-transition'
          width='100%'
        >
          <h3
            v-if='itemsToChange.length > 1'
            class='mt-2'
          >
            Employees Deleted
          </h3>
          <h3
            v-if='itemsToChange.length === 1'
            class='mt-2'
          >
            Employee Deleted
          </h3>
          <p v-if='itemsToChange.length > 1'>Selected employees were successfully deleted.</p>
          <p v-if='itemsToChange.length === 1'>Selected employee was successfully deleted.</p>
        </v-alert>
        <v-alert
          v-model='isReincorporateAlertShowing'
          class='rounded-xl'
          color='success'
          dark
          dense
          dismissible
          icon='mdi-information-outline'
          prominent
          transition='scale-transition'
          width='100%'
        >
          <h3
            v-if='itemsToChange.length > 1'
            class='mt-2'
          >
            Employees Reincorporated
          </h3>
          <h3
            v-if='itemsToChange.length === 1'
            class='mt-2'
          >
            Employee Reincorporated
          </h3>
          <p v-if='itemsToChange.length > 1'>
            Selected employees were successfully reincorporated.
          </p>
          <p v-if='itemsToChange.length === 1'>
            Selected employee was successfully reincorporated.
          </p>
        </v-alert>
      </template>
      <template #item.ineligible={item}>
        <i v-if="!item.ineligible" style="font-size: 20px" class="w-2 mdi-checkbox-marked green--text mdi"></i>
        <i v-else  style="font-size: 20px" class="mdi mdi-close-circle red--text"></i>
      </template>
      <template #item.actions={item}>
        <button 
          @click="openDeleteDialog([item])"
          class="mr-2 my-1 py-1 px-2 error white--text rounded-sm">
          <i style="font-size: 20px" class="mdi mdi-close-thick"></i>
        </button>
        <button
          @click="openReincorporateDialog([item])"
          class="mr-2 my-1 py-1 px-2 success white--text rounded-sm">
          <i style="font-size: 20px" class="mdi mdi-undo"></i>
        </button>
      </template>
    </v-data-table>
    <ReincorporateEmployeeDialog
      :is-open='isReincorporateDialogOpen'
      :items='itemsToChange'
      :is-loading='areEmployeesReincorporating'
      @submit='reincorporateEmployees'
      @close='isReincorporateDialogOpen = false'
    />
    <DeleteEmployeeDialog
      :is-open='isDeleteDialogOpen'
      :items='itemsToChange'
      @submit='openDeleteConfirmDialog'
      @close='isDeleteDialogOpen = false'
    />
    <DeleteEmployeeConfirmDialog
      :is-open='isDeleteConfirmDialogOpen'
      :items='itemsToChange'
      :is-loading='areEmployeesDeleting'
      @submit='deleteEmployees'
      @close='closeDeleteDialogs'
    />
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import ReincorporateEmployeeDialog from '@/views/employee/components/ReincorporateEmployeeDialog.vue'
import DeleteEmployeeDialog from '@/views/employee/components/DeleteEmployeeDialog.vue'
import DeleteEmployeeConfirmDialog from '@/views/employee/components/DeleteEmployeeConfirmDialog.vue'

export default {
  name: 'SuspendedEmployeesTable',
  components: {DeleteEmployeeConfirmDialog, DeleteEmployeeDialog, ReincorporateEmployeeDialog},
  data() {
    return {
      employees: [],
      visibleItems: [],
      selectedEmployees: [],
      tableSearch: '',
      isTableLoading: false,
      tableOptions: {},
      tableTotalItems: 0,
      tableHeaders: [
        {text: 'Name', align: 'start', value: 'fullName'},
        {text: 'Eligibility', align: 'center', value: 'ineligible', sortable: false},
        {text: 'Company', value: 'company'},
        {text: 'County', value: 'county.text', sortable: false},
        {text: 'Hierarchy', value: 'hierarchyDescription'},
        {text: 'Reports To', value: 'reportsToFullName'},
        {text: 'VUID', value: 'vuid'},
        {text: 'Last Login Time', value: 'lastLoginDate', align: 'center', sortable: false},
        {text: 'Actions', value: 'actions', sortable: false}
      ],
      itemsToChange: [],
      isReincorporateDialogOpen: false,
      areEmployeesReincorporating: false,
      isDeleteDialogOpen: false,
      isDeleteConfirmDialogOpen: false,
      areEmployeesDeleting: false,
      isDeleteAlertShowing: false,
      isReincorporateAlertShowing: false,
      setTimeoutTimer: null
    }
  },
  watch: {
    tableOptions: {
      async handler() {
        await this.getSuspendedEmployees()
      },
      deep: true
    }
  },
  methods: {
    ...mapActions({
      getSuspended: 'employeeStore/getSuspendedEmployees',
      delete: 'employeeStore/deleteEmployees',
      reincorporate: 'employeeStore/enableDisableMultipleEmployees'
    }),
    async getSuspendedEmployees() {
      this.employees = []
      this.visibleItems = []
      this.isTableLoading = true
      const {itemsPerPage, page, sortBy, sortDesc} = this.tableOptions
      const params = {
        page,
        per_page: itemsPerPage > 0 ? itemsPerPage : 'all',
        search: this.tableSearch || null,
        sort_by: sortBy ? sortBy[0] : null,
        order: sortBy ? (sortDesc[0] ? 'desc' : 'asc') : null
      }
      const {data: {data, meta: {total}}} = await this.getSuspended(params)
      this.tableTotalItems = total
      this.employees = data
      this.visibleItems = this.employees
      // this.visibleItems = this.employees.slice(0, 50)
      this.isTableLoading = false
    },
    openReincorporateDialog(items) {
      this.isReincorporateDialogOpen = true
      this.itemsToChange = items
    },
    openDeleteDialog(items) {
      this.isDeleteDialogOpen = true
      this.itemsToChange = items
    },
    openDeleteConfirmDialog() {
      this.isDeleteDialogOpen = false
      this.isDeleteConfirmDialogOpen = true
    },
    closeDeleteDialogs() {
      this.isDeleteDialogOpen = false
      this.isDeleteConfirmDialogOpen = false
    },
    async reincorporateEmployees() {
      this.areEmployeesReincorporating = true
      await this.reincorporate({employees: this.itemsToChange})
      this.selectedEmployees = []
      this.isReincorporateDialogOpen = false
      this.areEmployeesReincorporating = false
      this.showAlertMsg('reincorporate')
      await this.getSuspendedEmployees()
    },
    async deleteEmployees() {
      this.areEmployeesDeleting = true
      await this.delete({employees: this.itemsToChange})
      this.selectedEmployees = []
      this.closeDeleteDialogs()
      this.areEmployeesDeleting = false
      this.showAlertMsg('delete')
      await this.getSuspendedEmployees()
    },
    searchEmployees() {
      clearTimeout(this.setTimeoutTimer)
      this.setTimeoutTimer = setTimeout(() => this.tableOptions = {...this.tableOptions, page: 1}, 500)
    },
    // onTableScroll() {
    //   const container = this.$refs.tableRef
    //   if (container.scrollTop + container.clientHeight >= container.scrollHeight - 200) {
    //     const startIndex = this.visibleItems.length
    //     const endIndex = startIndex + 50
    //     this.visibleItems = this.visibleItems.concat(this.employees.slice(startIndex, endIndex))
    //   }
    // },
    showAlertMsg(type) {
      switch (type) {
        case 'delete':
          this.isDeleteAlertShowing = true
          break
        case 'reincorporate':
          this.isReincorporateAlertShowing = true
          break
      }
      this.hideAlertMsg()
    },
    hideAlertMsg() {
      window.setTimeout(() => {
        this.isDeleteAlertShowing = false
        this.isReincorporateAlertShowing = false
      }, 5000)
    }
  }
}
</script>

<style lang='scss' scoped>
.table-wrapper {
  max-height: 80vh;
  height: 100%;
  overflow-y: auto;

  ::v-deep .v-data-table__wrapper {
    padding-bottom: 80px;
  }

  ::v-deep .v-data-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: #ffffff;
  }
}
</style>
