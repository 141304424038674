<template>
  <v-dialog
    v-model='isOpen'
    max-width='500px'
    @click:outside='$emit("close")'
  >
    <v-card class='d-flex flex-column align-start pa-2'>
      <v-card-title class='text-center mb-5'>
        <v-icon class='mr-4 white--text' color='warning' large left>mdi-alert</v-icon>
        Overwrite Mailing Address
      </v-card-title>
      <v-card-subtitle>
        <b>You are about to overwrite the Mailing Address with the Voter Registration Address</b>
      </v-card-subtitle>
      <v-card-actions class='align-self-center'>
        <v-spacer></v-spacer>
        <v-btn
          class='px-14 mx-4 font-weight-bold white--text'
          color='primary'
          large
          outlined
          @click='$emit("close")'
        >
          Cancel
        </v-btn>
        <v-btn
          class='px-14 mx-4 font-weight-bold white--text'
          color='primary'
          large
          @click='$emit("submit")'
        >
          Confirm
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ApplyVoterRegistration',
  props: {
    isOpen: {
      type: Boolean
    },
  }
}
</script>
