<template>
  <v-card class='pa-5 ma-5 rounded-lg w-100' elevation='3'>
    <v-tabs-items v-model='selectedTab'>
      <v-tab-item
        v-if='selectedTab === 0'
        :value='0'
      >
        <ActiveEmployeesTable/>
      </v-tab-item>
      <v-tab-item
        v-else-if='selectedTab === 1'
        :value='1'
      >
        <SuspendedEmployeesTable/>
      </v-tab-item>
      <v-tab-item
        v-else-if='selectedTab === 2'
        :value='2'
      >
        <MismatchEmployeesTable/>
      </v-tab-item>
      <v-tab-item
        v-else-if='selectedTab === 3'
        :value='3'
      >
        <EmployeeRegistrationStatus/>
      </v-tab-item>
      <v-tab-item
          v-else-if='selectedTab === 4'
          :value='4'
      >
        <UploadHistoryTable/>
      </v-tab-item>
    </v-tabs-items>
    <v-tabs
      v-model='selectedTab'
      align-with-title
      class='tabs pt-3'
    >
      <v-tab
        v-for='tab in tabs'
        :key='tab.id'
      >
        {{ tab.title }}
      </v-tab>
    </v-tabs>
  </v-card>
</template>

<script>
import ActiveEmployeesTable from '@/views/employee/components/ActiveEmployeesTable.vue'
import SuspendedEmployeesTable from '@/views/employee/components/SuspendedEmployeesTable.vue'
import MismatchEmployeesTable from '@/components/MismatchEmployeesTable.vue'
import EmployeeRegistrationStatus from '@/components/registration-status/EmployeeRegistrationStatus.vue'
import UploadHistoryTable from "@/components/UploadHistoryTable.vue";

export default {
  name: 'BusinessEmployeesPage',
  components: {UploadHistoryTable, EmployeeRegistrationStatus, MismatchEmployeesTable, SuspendedEmployeesTable, ActiveEmployeesTable },
  data() {
    return {
      selectedTab: 0,
      tabs: [
        { id: 0, title: 'Active Employees' },
        { id: 1, title: 'Suspended Employees' },
        { id: 2, title: 'Employee Matching Exceptions' },
        { id: 3, title: 'Employee Registration Stage' },
        { id: 4, title: 'Upload History' }
      ]
    }
  }
}
</script>

<style lang='scss' scoped>
.tabs {
  border-top: 1px solid #5b8dd7;
}
</style>
