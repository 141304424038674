<template>
  <div v-frag>
    <v-data-table
      :headers='tableHeaders'
      :items='visibleItems'
      :loading='isTableLoading'
      :options.sync='tableOptions'
      :server-items-length='tableTotalItems'
      :items-per-page='10'
      loading-text='Loading employee exceptions... Please wait'
      no-data-text='There are no employee exceptions at the moment.'
      must-sort
    >
      <template #top>
        <v-toolbar flat>
          <v-toolbar-title class='font-weight-bold'>Employee Matching Exceptions</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
      </template>
      <template #item.action={item}>
        <button @click="toggleMismatchDialog(item)"
          class="warning white--text rounded-sm v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--small">
          Resolve Mismatch
        </button>
      </template>
    </v-data-table>
    <ResolveMismatchDialog
      :is-open='isMismatchDialogOpen'
      :employee='selectedEmployee'
      @close-dialog='closeDialog'
      @close-without-refresh='closeDialogWithoutRefresh'
    />
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import ResolveMismatchDialog from './ResolveMismatchDialog'

export default {
  name: 'MismatchEmployeesTable',
  components: {ResolveMismatchDialog},
  data() {
    return {
      visibleItems: [],
      selectedEmployee: {},
      isTableLoading: false,
      tableOptions: {},
      tableTotalItems: 0,
      tableHeaders: [
        {text: 'First Name', value: 'first_name'},
        {text: 'Last Name', value: 'last_name'},
        {text: 'Company Name', value: 'company'},
        {text: 'City', value: 'city'},
        {text: 'Zip', value: 'zip'},
        {text: 'County', value: 'county.text', sortable: false},
        {text: 'Action', value: 'action', align: 'center', sortable: false}
      ],
      isMismatchDialogOpen: false
    }
  },
  computed: {
    ...mapState({
      employees: s => s.adminStore.exceptionItems
    })
  },
  watch: {
    tableOptions: {
      async handler() {
        await this.getMismatchEmployees()
      },
      deep: true
    }
  },
  methods: {
    ...mapActions({
      getExceptionItems: 'adminStore/getExceptionItems'
    }),
    async getMismatchEmployees() {
      this.visibleItems = []
      this.isTableLoading = true
      const {itemsPerPage, page, sortBy, sortDesc} = this.tableOptions
      const params = {
        page,
        per_page: itemsPerPage > 0 ? itemsPerPage : 'all',
        sort_by: sortBy ? sortBy[0] : null,
        order: sortBy ? (sortDesc[0] ? 'desc' : 'asc') : null
      }
      const {meta: {total}} = await this.getExceptionItems(params)
      this.tableTotalItems = total
      this.visibleItems = this.employees
      // this.visibleItems = this.employees.slice(0, 50)
      this.isTableLoading = false
    },
    toggleMismatchDialog(item) {
      this.selectedEmployee = item
      this.isMismatchDialogOpen = true
    },
    async closeDialog() {
      this.isMismatchDialogOpen = false
      this.selectedEmployee = {}
      await this.getMismatchEmployees()
    },
    closeDialogWithoutRefresh() {
      this.isMismatchDialogOpen = false
      this.selectedEmployee = {}
    },
    // onTableScroll() {
    //   const container = this.$refs.tableRef
    //   if (container.scrollTop + container.clientHeight >= container.scrollHeight - 200) {
    //     const startIndex = this.visibleItems.length
    //     const endIndex = startIndex + 50
    //     this.visibleItems = this.visibleItems.concat(this.employees.slice(startIndex, endIndex))
    //   }
    // }
  }
}
</script>

<style lang='scss' scoped>
.table-wrapper {
  max-height: 80vh;
  height: 100%;
  overflow-y: auto;

  ::v-deep .v-data-table__wrapper {
    padding-bottom: 80px;
  }

  ::v-deep .v-data-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: #ffffff;
  }
}
</style>
