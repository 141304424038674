<template>
  <v-dialog
    v-model='isOpen'
    persistent
    max-width='1070px'
    transition='dialog-bottom-transition'
  >
    <v-card class='py-10 px-6'>
      <v-card-title
        class='pa-0 mb-6'
        tag='h3'
        style='font-size: 1.75rem'
      >
        Possible Employee Matches
      </v-card-title>
      <v-data-table
        v-model='mainSelected'
        class='mb-8'
        :headers='headers'
        hide-default-footer
        :items='items'
        single-select
        selectable-key='id'
        show-select
        @item-selected='selectEmployee'
      >
        <template #top>
          <v-toolbar-title>Original Employee Data</v-toolbar-title>
        </template>
        <template #item.fullMailAddress={item}>
          <span v-html='item.fullMailAddress'></span>
        </template>
        <template #item.fullVoterAddress={item}>
          <span v-html='item.fullVoterAddress'></span>
        </template>
      </v-data-table>
      <v-data-table
        class='mb-5'
        :headers='headers2'
        hide-default-footer
        :items='exceptionsTo'
        single-select
        selectable-key='id'
        show-select
        v-model='picked'
      >
        <template #top>
          <v-toolbar-title>Possible Matches</v-toolbar-title>
        </template>
        <template #item.fullVoterAddress={item}>
          <span v-html='item.fullVoterAddress'></span>
        </template>
      </v-data-table>
      <v-row class='d-flex align-center justify-end'>
        <v-btn @click='closeDialog' color='primary' elevation='0' class='mx-2'>Cancel</v-btn>
        <v-btn
          color='primary'
          @click='update'
          :loading='updateLoading'
          :disabled='!picked.length'
          elevation='0'
        >Update
        </v-btn>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'rescreen',
  props: {
    isOpen: {
      type: Boolean,
      required: true,
      default: false
    },
    mainItems: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  data() {
    return {
      headers: [
        { text: 'First Name', sortable: false, value: 'firstName', align: 'start' },
        { text: 'Last Name', sortable: false, value: 'lastName', align: 'start' },
        { text: 'Mailing Address', sortable: false, value: 'fullMailAddress', align: 'start' },
        { text: 'Mailing Address County', sortable: false, value: 'county.text', align: 'start' },
        { text: 'Voter Registration Address', sortable: false, value: 'fullVoterAddress', align: 'start' },
        { text: 'Voter Address County', sortable: false, value: 'voterCounty.text', align: 'start' },
        { text: 'Birth Year', sortable: false, value: 'birthDay', align: 'start' }
      ],
      headers2: [
        { text: 'First Name', sortable: false, value: 'firstName', align: 'start' },
        { text: 'Last Name', sortable: false, value: 'lastName', align: 'start' },
        { text: 'Voter Registration Address', sortable: false, value: 'fullVoterAddress', align: 'start' },
        { text: 'Voter Address County', sortable: false, value: 'voterCounty.text', align: 'start' },
        { text: 'Birth Year', sortable: false, value: 'birthDay', align: 'start' }
      ],
      exceptionsTo: [],
      picked: [],
      mainSelected: [],
      updateLoading: false,
      items: this.mainItems
    }
  },
  watch: {
    isOpen: {
      handler() {
        if (this.isOpen && this.items.length === 1) {
          this.mainSelected = [...this.items]
          this.exceptionsTo = this.mainSelected[0].exceptionsTo
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions({
      resolveRescreen: 'employeeStore/resolveRescreenAddress'
    }),
    selectEmployee(employee) {
      this.exceptionsTo = employee.item.exceptionsTo
    },
    async update() {
      const [res] = this.picked
      const [main] = this.mainSelected
      this.updateLoading = true
      const data = {
        selectedId: res.id,
        id: main.id
      }
      await this.resolveRescreen(data)
      this.clear()
      if (this.items?.length === 0) {
        this.$emit('update')
        this.closeDialog()
      }
      this.updateLoading = false
    },
    clear() {
      this.items = this.items.filter(el => !this.items.length ? [] : el.id !== this.mainSelected[0].id)
      this.exceptionsTo = []
      this.picked = []
      this.mainSelected = []
    },
    closeDialog() {
      this.picked = []
      this.mainSelected = []
      this.$emit('close')
    }
  }
}
</script>

<style scoped>

</style>
