<template>
  <v-dialog
    v-model='isOpen'
    max-width='500px'
    @click:outside='$emit("close")'
  >
    <v-card class='d-flex flex-column align-start pa-2'>
      <v-card-title class='text-center mb-5'>
        <v-icon class='mr-4 white--text' color='warning' large left>mdi-alert</v-icon>
        {{ title }}
      </v-card-title>
      <v-card-subtitle>
        <b v-if='items.length === 1'>You are about to suspend the following {{ subtitle }}:</b>
        <b v-else>You are about to suspend the following {{ subtitle }}s:</b>
      </v-card-subtitle>
      <div>
        <p
          v-for='item in items'
          :key='item.id'
          class='ml-8 font-weight-bold secondary--text'
        >
          {{ item.fullName }}
        </p>
      </div>
      <v-card-actions class='align-self-center'>
        <v-spacer></v-spacer>
        <v-btn
          class='px-14 mx-4 font-weight-bold white--text'
          color='primary'
          large
          outlined
          @click='$emit("close")'
        >
          Cancel
        </v-btn>
        <v-btn
          class='px-14 mx-4 font-weight-bold white--text'
          color='primary'
          large
          @click='$emit("submit")'
        >
          Confirm
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'SuspendItem',
  props: {
    items: {
      type: Array,
      required: true
    },
    isOpen: {
      type: Boolean
    },
    title: {
      type: String
    },
    subtitle: {
      type: String
    }
  }
}
</script>
