<template>
  <v-dialog
    v-model='isOpen'
    max-width='1000'
    width='100%'
    transition='dialog-bottom-transition'
    @click:outside='$emit("close")'
  >
    <v-card class='pt-8'>
      <v-form>
        <v-card-title class='px-7 py-0 mb-6'>
          <span class='headline font-weight-bold'>Search Employee</span>
        </v-card-title>
        <v-row class='ma-0 px-4'>
          <v-col cols='6' class='py-0'>
            <p class='font-weight-bold'>First Name</p>
            <v-text-field
              v-model='fields.firstName'
              dense
              outlined
              placeholder='i.e John'
            ></v-text-field>
          </v-col>
          <v-col cols='6' class='py-0'>
            <p class='font-weight-bold'>Middle Name</p>
            <v-text-field
              v-model='fields.secondName'
              dense
              outlined
              placeholder='i.e Phill'
            ></v-text-field>
          </v-col>
          <v-col cols='6' class='py-0'>
            <p class='font-weight-bold'>Last Name</p>
            <v-text-field
              v-model='fields.lastName'
              dense
              outlined
              placeholder='i.e Williams'
            ></v-text-field>
          </v-col>
          <v-col cols='6' class='py-0'>
            <p class='font-weight-bold'>VUID</p>
            <v-text-field
              v-model='fields.vuid'
              dense
              outlined
              placeholder='i.e 123'
            >
            </v-text-field>
          </v-col>
          <v-col cols='6' class='py-0'>
            <p class='font-weight-bold'>Mailing Address</p>
            <v-text-field
              v-model='fields.mailAddress'
              dense
              outlined
              placeholder='i.e Lincoln Square 123'
            ></v-text-field>
          </v-col>
          <v-col cols='6' class='py-0'>
            <p class='font-weight-bold'>Mailing City</p>
            <v-text-field
              v-model='fields.mailCity'
              dense
              outlined
              placeholder='i.e Chicago'
            ></v-text-field>
          </v-col>
          <v-col cols='6' class='py-0'>
            <p class='font-weight-bold'>Birth Year</p>
            <year-picker v-model='fields.birthDate'/>
          </v-col>
          <v-col cols='6' class='py-0'>
            <p class='font-weight-bold'>Mailing Zip Code</p>
            <v-text-field
              v-model='fields.mailZip'
              dense
              outlined
              placeholder='i.e 9851123'
            ></v-text-field>
          </v-col>
          <v-col cols='12' class='py-0'>
            <p class='font-weight-bold'>County</p>
            <v-select
              v-model='fields.voterCounty'
              :items='[{ value: null, text: "" }, ...this.counties]'
              class='mt-3'
              dense
              label='County'
              outlined
              placeholder='County'
            ></v-select>
          </v-col>
        </v-row>
        <v-card-text
          v-if='emptySearch'
          class='px-7 py-0 my-3'
        >
          This person was not found, but you can add them manually
        </v-card-text>
        <v-data-table
          v-if='foundEmployees.length'
          v-model='selectedNewEmployee'
          :headers='tableHeaders'
          :items='foundEmployees'
          :loading='isSearching'
          disable-sort
          color='primary'
          item-key='id'
          label='Employees'
          show-select
          single-select
        ></v-data-table>

        <v-card-actions class='justify-center'>
          <v-btn
            :disabled='!isAnyFieldFilled'
            :loading='isSearching'
            class='px-14 ma-4 font-weight-bold white--text'
            color='primary'
            large
            @click='searchEmployees'
          >
            Search
          </v-btn>
          <v-btn
            v-if='selectedNewEmployee.length'
            class='px-14 ma-4 font-weight-bold white--text'
            color='primary'
            large
            @click='selectEmployee'
          >
            Select Employee
          </v-btn>
          <v-btn
            v-if='emptySearch'
            class='px-14 ma-4 font-weight-bold white--text'
            color='primary'
            large
            @click='$emit("close")'
          >
            Add Employee manually
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import YearPicker from '@/components/year-picker.vue'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'SearchEmployeeDialog',
  components: { YearPicker },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      fields: {
        firstName: '',
        secondName: '',
        lastName: '',
        vuid: '',
        mailAddress: '',
        mailCity: '',
        birthDate: '',
        mailZip: '',
        voterCounty: ''
      },
      foundEmployees: [],
      selectedNewEmployee: [],
      isSearching: false,
      emptySearch: false,
      tableHeaders: [
        { text: 'First Name', value: 'first_name' },
        { text: 'Middle Name', value: 'middle_name' },
        { text: 'Last Name', value: 'last_name' },
        { text: 'VUID', value: 'vuid' },
        { text: 'Address', value: 'address' },
        { text: 'City', value: 'city' },
        { text: 'Birth Date', value: 'birth_day' },
        { text: 'Zip Code', value: 'zip' },
        { text: 'County', value: 'voterCounty.text' }
      ]
    }
  },
  computed: {
    ...mapState({
      counties: s => s.communicationStore.counties
    }),
    isAnyFieldFilled() {
      return Object.values(this.fields).some(el => el !== '' && el !== null)
    }
  },
  methods: {
    ...mapActions({
      searchEmployee: 'employeeStore/searchEmployee'
    }),
    async searchEmployees() {
      this.selectedNewEmployee = []
      this.isSearching = true
      const data = {
        first_name: this.fields.firstName,
        middle_name: this.fields.secondName,
        last_name: this.fields.lastName,
        vuid: this.fields.vuid,
        address: this.fields.mailAddress,
        city: this.fields.mailCity,
        birth_day: this.fields.birthDate,
        zip: this.fields.mailZip,
        county_id: this.fields?.voterCounty
      }
      const filteredData = Object.keys(data)
        .filter(key => !!data[key] === true)
        .reduce((obj, key) => {
          obj[key] = data[key]
          return obj
        }, {})
      this.foundEmployees = await this.searchEmployee(filteredData)
      this.emptySearch = !this.foundEmployees.length
      this.isSearching = false
    },
    selectEmployee() {
      this.$emit('found', this.selectedNewEmployee[0])
    }
  }
}
</script>

<style scoped>

</style>
